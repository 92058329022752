<template>
  <lightspeed-layout>
    <p>
      Please select a tab to the left.
    </p>
  </lightspeed-layout>
</template>

<script>
import LightspeedLayout from "./LightspeedLayout.vue";
export default {
  components: {LightspeedLayout}
}
</script>

<style>

</style>